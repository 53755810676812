
/* ----------------------------------------
    Imports
---------------------------------------- */
import { Component, Prop, Vue } from 'vue-property-decorator';
import ErrorResponseMixin from '@mixin/errorResponse';
/* ----------------------------------------
    Component
---------------------------------------- */
@Component({
    data() {
        return {
            password: '',
            repeatpassword: '',
            email: this.$route.query.email,
            token: this.$route.query.token,
            terms: false,
        };
    },
    mixins: [
        ErrorResponseMixin,
    ],
    methods: {
        submit() {
            this.$store.dispatch('user/reset', {
                email: this.email,
                token: this.token,
                password: this.password,
                password_confirmation: this.repeatpassword,
            }).then((response) => {
                if (response instanceof Error) {

                    // failure msg
                    if (response.response.status == 422){
                        this.$store.dispatch('setToastMessage', this.getErrorMessage(response))
                        this.$store.dispatch('setToastButton', '');
                    } else {
                        this.$store.dispatch('setToastMessage', response.response.data.message)
                        this.$store.dispatch('setToastButton', '');
                    }
                    return;
                }

                // success msg
                this.$store.dispatch('setToastMessage', 'Wachtwoord succesvol aangepast.')
                this.$store.dispatch('setToastButton', '');
                this.$router.push({
                    path: '/login',
                })
            });
        }
    },
})
/* ----------------------------------------
    Export
---------------------------------------- */
export default class ResetForm extends Vue {
    @Prop(Boolean) showTerms: boolean;
}
