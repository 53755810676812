

/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import ResetForm from '@part/auth/ResetForm.vue';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        components: {
            ResetForm,
        },
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class ResetView extends Vue {}
    
